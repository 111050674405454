<template>
  <div class="protocol">
    <PageNav title="易备用户服务使用协议" />
    <div class="content">
      <h3>1、服务条款的确认和接纳</h3>
      <p>
        易备（以下简称“本平台”）的所有权和运作权归天仰科技（北京）有限公司所有。用户承诺在仔细阅读和完全接受《易备用户服务使用协议》（以下简称“本协议”）项下全部条款的基础上使用本平台的各项服务。用户接受本协议点击“同意以下服务条款，提交注册信息”按钮完成注册即表示完全接受以上述及的所有条款。
      </p>
      <p>
        易备可能根据法律法规的要求或业务运营的需要，对本协议服务条款不时进行增加、删减、修改及/或补充（以下统称“修改”）。除非另有规定，否则任何修改将在该等修改内容发布之时立即生效，您对本平台的注册、使用、及继续使用均表明您接受此等修改。如果您不同意本协议服务条款（包括易备可能不定时对其或其中引述的其他规则所进行的任何修改）的任一规定，则请勿注册或使用本平台，或您可以主动取消本平台提供的服务。
      </p>
      <p>为了便于您了解适用于您的条款和条件，易备将在本平台上发布易备对本协议服务条款的修改，您应不时地审阅本协议服务条款以及经参引而并入其中的其他规则。</p>

      <h3>2、服务说明</h3>
      <p>易备运用自己的操作系统通过国际互联网络为用户提供各项服务，是一家网络服务提供商，且这种服务是免费的。用户必须：</p>
      <p>（1）提供设备，包括个人电脑一台、调制解调器一个及配备上网装置。</p>
      <p>（2）个人上网和支付与此服务有关的电话费用。</p>
      <p>考虑到天仰科技（北京）有限公司产品服务的重要性，用户同意：</p>
      <p>（1）提供设备，包括个人电脑一台、调制解调器一个及配备上网装置。</p>
      <p>（2）不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。</p>
      <p>（3）易备保留随时变更、中断或终止部分或全部网络服务的权利。</p>
      <p>用户可授权易备向第三方透露其注册资料，否则易备不能公开用户的姓名、住址、出件地址、电子邮箱、账号。除非：</p>
      <p>（1）用户要求天仰科技（北京）有限公司或授权某人通过电子邮件服务或其他方式透露这些信息。</p>
      <p>（2）相应的法律、法规要求及程序服务需要天仰科技（北京）有限公司提供用户的个人资料。</p>
      <p>用户在注册账号或使用本平台服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，用户需要填写真实的身份信息。若您填写的信息不完整，则无法使用本平台服务或在使用过程中受到限制。</p>
      <p>用户应当为自己提供资料的合法性负责，如果用户提供的资料不准确，不真实，不合法或无效，易备保留中断直至终止用户使用易备部分或全部服务的权利。</p>
      <p>用户在享有易备各项服务的同时，同意接受易备提供的各类信息服务。</p>

      <h3>3、使用规则</h3>
      <p>（1）用户在申请使用易备提供的网络服务时，必须向易备提供准确的个人资料，如个人资料有任何变动，必须及时更新。若您填写的信息不完整或不准确，则无法使用本平台服务或在使用过程中受到限制。</p>
      <p>（2）用户注册成功后，易备将给予您一个用户账号及相应的密码，该用户账号和密码由用户负责保管；用户应当对以其用户账号进行的所有言论、行为、活动和事件承担法律责任。</p>
      <p>
        &nbsp;&nbsp;用户的账号名称应当符合《互联网用户账号名称管理规定》之规定，在账号名称、头像和简介等注册信息中不得出现违法和不良信息。易备对用户提交的账号名称、头像和简介等注册信息有权进行审核，对含有违法和不良信息的，不予注册。对于已经注册的含有违法或者不良信息的账号名称、头像、简介，易备有权采取通知限期改正、暂停使用、注销登记等措施，造成的后果由用户自行承担。对冒用、关联机构或社会名人注册账号名称的，易备有权注销其账号，并向互联网信息内容主管部门报告。
      </p>
      <p>&nbsp;&nbsp;易备对用户提交的账号名称等信息予以审核通过并不代表网站对其予以任何批准、许可、授权、同意或者支持，用户仍然应当自行承担其法律责任，且网站保留进行后续不时审核并予以处理的权利。</p>
      <p>&nbsp;&nbsp;易备保护用户信息及公民个人隐私，自觉接受社会监督，及时处理公众举报的账号名称、头像和简介等注册信息中的违法和不良信息。</p>
      <p>（3）用户在使用易备某些频道时，易备需要对用户身份进行特别验证，具体的验证方式以各频道页面显示为准。</p>
      <p>（4）用户在使用易备服务，应当爱国、守法、自律、真实、文明，必须遵循以下原则：</p>
      <p>&nbsp;&nbsp;(a) 遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线；</p>
      <p>&nbsp;&nbsp;(b) 不得为任何非法目的而使用网络服务系统；</p>
      <p>&nbsp;&nbsp;(c) 遵守所有与网络服务有关的网络协议、规定和程序；</p>
      <p>&nbsp;&nbsp;(d) 不得利用易备服务系统进行任何可能对互联网的正常运转造成不利影响的行为；</p>
      <p>&nbsp;&nbsp;(e) 不得利用易备服务系统传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</p>
      <p>&nbsp;&nbsp;(f)
        不得利用易备服务系统进行任何不利于易备的行为。（5）易备用户账号的有效期为3个月。如果一个易备用户账号在任意连续九十日不曾使用该账号，即在该任意连续九十日未以该账号名义登录易备，则该账号有可能被新的用户注册，届时该账号原有信息将全部丢失。
      </p>
      <p>（5）用户不得以任何形式和任何目的对注册账号和所发布的信息进行有偿交易。一经发现，易备有权删除交易相关的所有注册账号极其发布的相关信息。</p>

      <h3>4、用户隐私制度</h3>
      <p>尊重用户个人隐私是易备的一项基本政策。易备将按照本协议及《隐私政策》
        ）的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《隐私政策》有相冲突的，及本协议对个人信息保护相关内容未作明确规定的，以《隐私政策》的内容为准。如您对《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关协议或易备的隐私措施的问题请与我们联系。
      </p>
      <p>易备不会未经您的同意将您的个人信息转移或披露给任何非关联的第三方，，除非易备在诚信的基础上认为透露这些信息在以下几种情况是必要的：</p>
      <p>（1）遵守有关法律法规的规定，或法院、公安部门等政府机关有权部门要求。</p>
      <p>（2）遵从易备产品服务程序或提供本平台服务的必要。</p>
      <p>（3）在紧急情况下竭力维护用户个人和社会大众的隐私安全。</p>
      <p>（4）为完成易备合并、分立、收购或资产转让而转移。</p>
      <p>（5）为维护易备的利益或易备认为必要的其他情况下。</p>
      <p>用户在此授权易备可以向其电子邮箱发送商业信息。</p>
      <p>易备将运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。</p>

      <h3>5、用户的账号、密码和安全性</h3>
      <p>您一旦注册成功成为用户，您将得到一个密码和账号。如果您未保管好自己的账号和密码而对您、易备或第三方造成的损害，您将承担全部责任。</p>
      <p>每个用户都要对其账号中的所有言论、行为、活动和事件承担全部责任。您可随时改变您的密码和图标，也可以结束旧的账号重开一个新账号。用户同意若发现任何非法使用用户账号或安全漏洞的情况，立即通告易备。</p>

      <h3>6、免责声明</h3>
      <p>
        用户明确同意使用本平台服务的使用所存在的风险将完全由您自己承担，因用户使用易备服务而产生的一切后果也由您自己承担，易备对您不承担任何责任。若易备已经明示其网络服务提供方式发生变更并提醒您应当注意事项，您未按要求操作所产生的一切后果亦由您自行承担。
      </p>
      <p>易备明确表示不提供任何类型的保证，不论是明确的或隐含的。包括并不限于：</p>
      <p>&nbsp;&nbsp;（1）不保证服务一定能满足您的要求或您的使用预期。</p>
      <p>&nbsp;&nbsp;（2）不保证服务不会中断，且对服务的及时性、安全性、准确性都不作保证。</p>
      <p>&nbsp;&nbsp;（3）不保证信息能够准确、及时、顺利地传送。</p>
      <p>&nbsp;&nbsp;（4）对于本平台服务包含的或用户经由或从任何与本平台服务有关的途径所获得的任何内容、信息或广告，不保证其正确性或可靠性。</p>
      <p>&nbsp;&nbsp;（5）对于用户经本平台上的信息、广告、展示而购买、取得的任何产品或服务，均不承担任何保证责任。用户须对相关信息自行甄别并自行承担相应风险。</p>
      <p>易备对由于下列原因导致的直接的、间接的、偶然的、惩罚性的及继发的损害不承担责任：</p>
      <p>&nbsp;&nbsp;（1）不正当使用本平台产品服务；</p>
      <p>&nbsp;&nbsp;（2）在网上购买商品或类似服务；</p>
      <p>&nbsp;&nbsp;（3）在网上进行交易；</p>
      <p>&nbsp;&nbsp;（4）非法使用本平台服务；</p>
      <p>&nbsp;&nbsp;（5）用户传送的信息有所变动。</p>

      <h3>7、未经易备同意禁止进行商业性行为</h3>
      <p>用户承诺未经易备书面同意，不得利用易备各项服务在易备或相关网站上进行销售或其他商业性行为。用户违反此约定，易备将依法追究其违约责任，由此给易备造成损失的，易备有权进行追偿。</p>

      <h3>8、信息的储存及限制</h3>
      <p>易备对本平台上所有服务将尽力维护其安全性及方便性，但对服务中出现信息删除或储存失败不承担任何责任。</p>
      <p>用户理解并接受是否下载或通过易备产品服务取得任何信息资料取决于用户自己，并由用户自行承担系统受损或资料丢失的所有风险和责任.</p>
      <p>易备保留在任何时候为任何理由而不经通知地过滤、移除、筛查或编辑本平台上发布或存储的任何内容的权利，用户须自行负责备份和替换在本平台发布或存储的任何内容，成本和费用自理。</p>
      <p>
        易备用户账号的有效期为3个月。如果一个易备用户账号在任意连续九十日不曾使用，即在该任意连续九十日未以该账号名义登录易备，则该账号有可能被新的用户注册，届时该账号原有信息将全部丢失，所有信息或资料丢失的风险由用户自行承担。
      </p>
      <p>易备保留判定用户的行为是否符合易备服务条款要求的权利，如果用户违背了本平台服务条款的规定，易备将会中断、终止该用户本平台部分或全部服务直至注销该用户本平台注册账号。</p>

      <h3>9、保障</h3>
      <p>用户同意保障和维护易备全体成员的利益，负责支付由于您在使用过程中超出服务范围及/或违反服务条款而引起纠纷的损害补偿费用及律师费用，，其他人使用您的电脑、账号和其它知识产权的追索费。</p>

      <h3>10、结束服务</h3>
      <p>
        用户或易备可随时根据实际情况中断服务。易备有权随时修改、中断及/或终止全部或部分服务而无须不需对任何用户或第三方负责。用户若反对本协议任何服务条款的建议或对后来的条款修改有异议，或对易备服务不满，用户可以不再使用易备服务。结束用户服务后，用户使用易备服务的权利立即终止。从该时起，易备不再对用户承担任何义务。
      </p>

      <h3>11、通告</h3>
      <p>
        所有发给用户的通告都可通过电子邮件或常规的信件或网页公告的方式通知或公布。易备会通过邮件服务或网页公告或易备认为适当的方式发送消息给用户，告知您服务条款的修改、服务变更及/或其它重要事项。易备通过以上任一方式向用户发报消息后，用户未在3日内通过书面方式提出异议，视为接受新消息的内容。同时，易备保留对用来申请易备用户注册的电子邮箱投放商业性广告的权利。
      </p>

      <h3>12、参与广告策划</h3>
      <p>
        易备同意并许可，用户可在您发表的信息中加入宣传资料或参与广告策划，在易备各项免费服务上展示您的产品。任何这类促销方法，包括运输货物、付款、服务、商业条件、担保及与广告有关的描述都只是在相应的用户和广告销售商之间发生，易备不承担任何责任。易备没有任何义务为这类广告及/或销售承担任何责任，也不从中获取任何利益。
      </p>

      <h3>13、知识产权</h3>
      <p>
        用户保证和声明对其所提供的作品拥有完整的合法的著作权，保证易备使用该作品不违反国家的法律法规，也不侵犯任何第三方的合法权益或承担任何义务。用户应对其所提供作品因形式、内容及授权的不完善、不合法所造成的一切后果承担完全责任。用户同意易备对其上传作品在全世界范围内享有免费的、永久性的、不可撤消的、独家的和完全的许可使用和再许可的权利。此许可和再许可权利包括但不限于此作品的著作权、邻接权及获得利益等权利。
      </p>
      <p>易备保留对其网站所有内容进行实时监控的权利，并有权依其自主判断对任何违反本协议约定的作品实施删除。易备对于删除用户作品引起的任何后果或导致用户的任何损失不负任何责任。</p>
      <p>
        因用户作品的违法或侵害第三人的合法权益而导致易备或其关联方对第三方承担任何性质的赔偿、补偿或罚款而遭受损失（直接的、间接的、偶然的、惩罚性的和继发的损失，包括但不限于易备为此而支出的律师费、公证费、差旅费），用户对于易备或其关联方蒙受的上述损失承担全部赔偿责任。
      </p>

      <h3>14、内容的所有权</h3>
      <p>
        内容的定义包括：文字，软件，图片，图形，图表，音频，视频，网页，域名；广告中的全部内容；电子邮件的全部内容；易备平台服务为用户提供的商业信息，所有这些内容均受版权、商标、标识和其它财产所有权法律的保护。用户只能在易备及/或相关权利人的授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
      </p>

      <h3>15、适用法律及争议管辖</h3>
      <p>用户和易备一致同意有关本协议以及使用易备的服务产生的争议均适用中华人民共和国法律交由易备住所地有管辖权的法院诉讼解决。</p>

      <h3>16、未成年人用户特别提示</h3>
      <p>易备鼓励父母或监护人指导未满十八岁的未成年人使用易备的服务。易备建议未成年人鼓励您的父母或监护人阅读本协议，并建议未成年人在提交注册使用本平台服务之前寻求父母或监护人的指导和同意。</p>
      <p>未成年人用户必须遵守全国青少年网络文明公约：</p>
      <p>要善于网上学习，不浏览不良信息；</p>
      <p>要增强自护意识，不随意约会网友；</p>
      <p>要维护网络安全，不破坏网络秩序；</p>
      <p>要有益身心健康，不沉溺虚拟时空。</p>

      <h3>17、其他</h3>
      <p>（1）易备将视向用户所提供服务内容之特性，要求用户在注册易备提供的有关服务时，遵守特定的条件和条款；如该等条件和条款与以上服务条款有任何不一致之处，则以该等条件和条款为准。</p>
      <p>（2）若本协议有任何服务条款与法律相抵触，则该等条款将按尽可能接近的方法重新解析，而本协议其他条款仍持续对用户具有法律效力和影响。</p>
      <p>（3）本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
    </div>
  </div>
</template>

<script>
import PageNav from '@/components/PageNav';
export default {
  name: "Protocol",
  components: { PageNav }
}
</script>

<style lang="scss" scoped>
  .protocol {
    .content {
      padding: 10px 16px;
      p {
        margin-bottom: 1em;
      }
    }
  }
</style>